angular.module('monjApp').value('appSettings', {
    title: 'Monj Application',
    version: '1.0',

    apiKey: "AIzaSyBrz_mgJ31XqRuLYtKLXIQwvxT10WguAmU",
    authDomain: "monj-dev.firebaseapp.com",
    databaseURL: "https://monj-dev.firebaseio.com",
    projectId: "monj-dev",
    storageBucket: "monj-dev.appspot.com",
    messagingSenderId: "798820200646",

    contentfulAccessToken: '4721617a86b4c993e1c0e8cdbac28cec2fa8ffc91495e44a30dc637b430d7a37',
    contentfulHost: 'preview.contentful.com',
    contentfulSpace: '5u7jt849cgc0',

    monjApiKey: '1',
    monjURL: 'https://apidev.monj.com/v1',
    monjUploadURL: 'https://apidev.monj.com',
    
    segmentKey: 'VMI51DOfXGL4EpK3lsEo9rRn87kwePxi',
    
    pageTitle: ' - [DEV]',

    isProduction: false
});
